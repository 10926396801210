<script lang="ts" setup>
import { ContainerWidgetSidebar, ParagraphContainerWidgetSidebar } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphContainerWidgetSidebar
}>()

const containerWidgets = props.entity.fieldContainerWidget?.filter(Boolean)
const containerWidget = containerWidgets && (containerWidgets[0].entity as ContainerWidgetSidebar)
const sections = useSidebarSections(containerWidget?.fieldWidgets || [])
</script>

<template>
  <component
    :is="section?.name"
    v-for="(section, index) in sections"
    :key="`${section?.name}-${index}`"
    :entity="section.entity"
  />
</template>
